import "./App.css";

import React from "react";

import Home from "./pages/home/home-page";

function App() {
  return <Home />;
}

export default App;
